import React from "react";

// Customizable Area Start
import { Box, Button, Grid, IconButton, TextField, Typography, styled } from "@material-ui/core";
import { slider2, view, roght } from "./assets";
const loginbg = require("../assets/loginbg.jpg");
import {
  createTheme,ThemeProvider,
}from "@material-ui/core/styles";
import { VisibilityOffOutlined as VisibilityOffOutlinedIcon, 
    VisibilityOutlined as VisibilityOutlinedIcon }
from '@material-ui/icons';
import MessageBox from "../../../components/src/messageBox";
// Customizable Area End

import ClientEmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./ClientEmailAccountRegistrationController.web";

export default class ClientEmailAccountRegistration extends ClientEmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFirstPart() {
    const { email, errors } = this.state;
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box className="imageViewBoxx">
              <Typography className="logohereTextt">{configJSON.logoTextHere}</Typography>
              <img src={slider2} className="imgPicc" />
                  <Box className="boxTextStylee">
                    <Typography className="msgTextt">Your new construction management tool. </Typography>
                    <Typography className="smallTextt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque.</Typography>
                  </Box>
          </Box></Grid>
        <Grid item xs={12} md={6} 
        className="backgroundColorImagee">
          <Box data-test-id='oKay' style={webStyles.rightBox2} 
          component="form">
           <Typography component="h2" style={webStyles.signuptextt}>
                {configJSON.btnTextWelcome}</Typography>
              <Typography component="h2" 
              style={webStyles.labelTextClientt}>{configJSON.btnTextLabelText}
              </Typography>
              <Box className="yourEmailContaiann"><Typography className="textStylee">{configJSON.labelEmail}</Typography>
              <TextField
          name="email" // Ensure the name attribute is set
          InputLabelProps={{ shrink: true }}
          disabled={true}
          value={email}
          error={!!errors.email}
          helperText={errors.email}
          placeholder="Enter your email"
          size="small"
          variant="outlined"
          data-test-id="txtInputEmail"
          InputProps={{ style: inputStyles }}
          FormHelperTextProps={{ style: errorMessageStyles }}
          fullWidth
        />
                <Box className="singupButtonn">
                  <button
                    type="submit"
                    data-test-id="clickButton"
                    style={this.state.validations.emailValid ? webStyles.signupTextStylewithh : webStyles.signupTextStylee}
                    color="primary"
                    disabled={!this.state.validations.emailValid}
                    onClick={() => this.handleContinue(this.state.email)}>
                    {configJSON.btnTextContinnue}
                  </button></Box></Box>
               </Box></Grid>
      </Grid>);}

  renderSecondPart() {
    return (
      <Grid container>
        <Grid item xs={12}
         md={6}>
          <Box className="imageViewBoxx">
            <Typography className="logohereTextt">
                {configJSON.logoTextHere}</Typography>
              <img src={slider2} 
              className="imgPicc" />
                  <Box className="boxTextStylee">
                    <Typography 
                    className="msgTextt">Your new construction management tool.
                    </Typography>
                    <Typography className="smallTextt"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque.
                    </Typography>
                  </Box>
          </Box></Grid>
        <Grid item xs={12} md={6} className="backgroundColorImagee">
          <Box data-test-id='setPasswordForm' style={webStyles.rightBoxx} component="form" onSubmit={this.handleSubmit}>
            <Box style={webStyles.passwordsContainer}>
              <Typography component="h2" style={webStyles.signuptextt}>
                {configJSON.btnTextSetPassword}
              </Typography>
              <Typography component="h2" style={webStyles.labelTextClientt}>
                {configJSON.labelTextClient}
              </Typography>
              <Box><Typography className="textStyle">{configJSON.labelPassword}</Typography>
                <TextField
                
                  type={this.state.showPassword ? "name" : "text"}
                  error={!!this.state.errors.password}
                  helperText={this.state.errors.password}
                  FormHelperTextProps={{ style: errorMessageStyles }}
                  data-test-id="txtInputPassword"
                  placeholder="Enter your password"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={this.state.showPassword ? this.state.originalPassword : this.state.asterisk}
                  onChange={this.handleChangePassword}
                  InputLabelProps={{ shrink: true }}
                  name="password"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={this.toggleVisibility} edge="end" style={webStyles.iconColor}>
                        {this.state.showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    ),style: inputStylesPass }}/>
              </Box>
              <Box style={{marginTop:"15px"}}>
                <Typography className="textStyle">{configJSON.confirmPasswordText}</Typography>
                <TextField
                  placeholder="Confirm your password"
                  size="small"
                  variant="outlined"
                  type={this.state.showPassword1 ? "name" : "text"}
                  data-test-id="txtInputConfirmPassword"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={this.state.showPassword1 ? this.state.originalPassword1 : this.state.asterisk1}
                  onChange={this.handleChangePassword1}
                  name="confirmPassword"
                  error={!!this.state.errors.confirmPassword}
                  helperText={this.state.errors.confirmPassword}
                  FormHelperTextProps={{ style: errorMessageStyles }}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={this.toggleVisibility1} edge="end" style={webStyles.iconColor}>
                        {this.state.showPassword1 ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    ),
                    style: inputStylesPass
                  }}
                />
              </Box>
              <Grid container>
                <Grid item xs={12}>
                  <Box className="yourPasswordContaian">
                  <Box>
                          <Typography component={"span"} className="textStyle">{configJSON.textOne}</Typography>
                        </Box>
                    <Box style={webStyles.validationText}>
                      {this.state.validations.hasUpper ? <img src={roght} /> : <img src={view} />}
                      <Typography component="span" className="errormessage">{configJSON.textOne1}</Typography>
                    </Box>
                    <Box style={webStyles.validationText}>
                      {this.state.validations.hasSpecialChar ? <img src={roght} /> : <img src={view} />}
                      <Typography component="span" className="errormessage">{configJSON.textThree}</Typography>
                    </Box>
                    <Box style={webStyles.validationText}>
                      {this.state.validations.hasNumber ? <img src={roght} /> : <img src={view} />}
                      <Typography component="span" className="errormessage">{configJSON.textFour}</Typography>
                    </Box>
                    <Box style={webStyles.validationText}>
                      {this.state.validations.minLength ? <img src={roght} /> : <img src={view} />}
                      <Typography component="span" className="errormessage">{configJSON.textFive}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box className="singupButtonn">
                <Button
                  data-test-id="submitBtn"
                  type="submit"
                  color="primary"
                  disabled={this.handleValidate()}
                  fullWidth
                  style={this.handleValidate() ? webStyles.signupTextStylee : webStyles.signupTextStylewithh}
                >
                  {configJSON.btnTextSetPassword}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
    {this.state.messageToggle && <MessageBox message={this.state.tostifymessage} status={this.state.status} stylePosition={{top:"50px"}}/>}
        <ImageWrapper>
        {!this.state.showSecondPart ? ( 
           this.renderFirstPart()
                   ) : (
          this.renderSecondPart())}
        </ImageWrapper></ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ImageWrapper = styled(Box)(
    {'& .imageViewBoxx':{
    display:'flex',
    flexDirection:"column",
    alignItems:"center",
    justifyContent:'space-between',
    padding:"2.5%",
    boxSizing:"border-box",
    textAlign:"center",
    backgroundImage:`url(${loginbg})`,
    backgroundSize:"cover",
    backgroundPosition:"center",
    height: "100vh" ,
    '@media (max-width:360px)':{ alignItems: "center",
      justifyContent:'center',
      boxSizing:"border-box",
      textAlign:"center",
      backgroundImage:`url(${loginbg})`,
      backgroundSize:"cover",
      backgroundPosition:"center",
      height:"90vh",
    },},
  "& .boxTextStylee":{
    width:"85%",
    paddingTop:'20px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",},
  "& .backgroundColorImagee":{
    backgroundColor:'#325962',
    height:"100vh",
  },
  "& .yourEmailContaiann": {marginTop:'20px',width:"80%"},
  "& .inputStylesPass":{
    backgroundColor:"#F6F8F9",
    borderRadius:"8px",
    fontWeight:400,
    fontSize:"16px",
    border:"1px solid #CBD5E1",
    background:"#FCFDFE",
    paddingTop:"8px",
    paddingBottom:'8px',
    top:'8px'},
  "& .yourPasswordContaian ":{
    marginTop: '20px'},
  "& .errorHanlerMsg":{
    fontSize:"12px",
    fontWeight:"400",
    lineHeight:"16px",
    fontFamily:"'Poppins', sans-serif",
    textAlign:"left",
    userSelect:"none",
    color:"red"},
  " & .singupButtonn": {
    marginTop: '32px'},
  "& .imgPicc": {
    maxWidth: "474px",
    height: "65%",
    width: "60%",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width: 1100px)': {
        width: "50%", 
        height: "50%",
    },
    '@media (max-width: 800px)': {
        width: "40%", 
        height: "40%",
    },
    '@media (max-width: 500px)': {
        width: "90%", 
        height: "90%",
    },
    '@media (max-width: 360px)': {
        width: "350px",
        height: "350px",
    },
  },

  "& .logohereTextt":
  {
    width:"100%",
    fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "white",
    '@media (max-width:1600px)':{
      fontSize:"25px",
    },
    '@media (max-width:1200px)':{
      fontSize:"20px",
    },
    '@media (max-width:900px)':{
      fontSize:"30px",
    },
  },
  "& .textStylee": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    color: "#334155",
    marginTop: '8px',
  },
  "& .errormessage": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginLeft: "6px",
    color: "#334155",
  },
  "& .textStyle": {
    fontFamily: "Poppins",
    fontWeight:600,
  },
  "& .msgTextt":
  {color:"white",
    lineHeight:"1.2",
    fontFamily:"Poppins",
    fontSize:"38px",
    fontWeight:700,
    marginTop:'13px',
    width:"80%",
    '@media (max-width:1600px)':{
      fontSize:"30px",
    },
    '@media (max-width:1200px)':{
      fontSize:"22px",
    },
    '@media (max-width:900px)':{
      fontSize:"38px",
    },
    '@media (max-width:412px)':
    { marginLeft: '36px',
      marginRight: '36px',
      textAlign: "center",
      color: "white",
      lineHeight: "1.2",
      fontFamily: "Poppins",
      fontSize: "30px",
      fontWeight: 700,
      marginTop: '8px',
    },
    '@media (max-width:360px)': {
      marginLeft: '36px',
      marginRight: '36px',
      textAlign: "center",
      color: "white",
      lineHeight:"1.2",
      fontFamily:"Poppins",
      fontSize: "30px",
      fontWeight: 700,
      marginTop: '8px',},
    },
  "& .smallTextt":{
    textAlign:"center",
    fontFamily:"'Poppins', sans-serif",
    fontWeight:100,
    fontSize:"18px",
    color:"white",
    marginTop:"13px",
    '@media (max-width:1600px)':{
      fontSize:"15px",
    },
    '@media (max-width:1200px)':{
      fontSize:"12px",
    },
    '@media (max-width:900px)':{
      fontSize:"18px",
    },
    '@media (max-width:412px)':
    {paddingLeft: '22%',
      paddingRight: '22%',
      textAlign: "center",
    },
    '@media (max-width:360px)':
    {paddingLeft: '22%',
    paddingRight: '22%',
    textAlign: "center",},}
  }
  );

  const webStyles = {errStyle: {
    fontFamily:"'Poppins', sans-serif",
    fontWeight:"400",
    lineHeight:"1.5",
    letterSpacing:"0.00938em",
    color:"red",
    fontSize:"14px",},
  textColor:{
    fontWeight:"400",
    fontFamily:"Poppins",
    lineHeight:"24px",
    textAlign:"left",
    color:"#334155",
    fontSize:"16px", } as const,
    
    mainbox:{display:'flex',
    height:'100vh',
    alignItems:"stretch",
    justifyContent:"stretch",
    backgroundColor:"rgba(50, 89, 98, 1)",
    padding:0,
    overflowY:"hidden"} as const,
  rightBox2:{
    backgroundColor:"white",
    borderTopLeftRadius:"24px",
    borderBottomLeftRadius:"24px",
    minHeight:"100vh",
    height:"100vh",
    width:"100%",
    display:"flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
  } as const,
  rightBoxx:{
    backgroundColor: "white",
    borderTopLeftRadius: "24px",
    borderBottomLeftRadius: "24px",
    display:"flex",
    paddingTop:"50px",
    paddingBottom:"30px",
    minHeight: "100vh",
    height: "100vh",
    overflow:"auto",
    marginBottom: "0",
    justifyContent: 'center',
    boxSizing: "border-box",
  } as const,
  passwordsContainer:{
    width:"80%"
  } as const,
  leftbox: {
    padding: "48px",
    height: "100vh",
    width: "50%",
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
    backgroundImage: `url(${loginbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  } as const,
  signupText: {
    fontWeight: "bold",
    textAlign: "center",
    color: "#0F172A",
    fontSize: "1.875rem",
  },
  dontText: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#334155',
  } as const,
  linktext: {
    color: "#237182",
    fontSize: "18px"
  },
  signuptextt: {
    fontSize: "30px",
    fontFamily: "Poppins",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color:"#0F172A"
  } as const,
  validationText: {marginTop:'6px',
    alignItems:"center",
    fontFamily: 'Poppins',} as const,
  iconColor:{ color: '#94A3B8' }as const,
    privacytext: {color: '#237182',
    fontSize:'14px',
    fontFamily:"'Poppins', sans-serif",
    fontWeight:"600",
    lineHeight:"22px",
    textAlign:"left",
    marginRight:"3px",
    userSelect:"none",
    cursor:'pointer',} as const,
  privacytext1: {fontFamily:"'Poppins', sans-serif",
    fontSize:"14px",
    fontWeight:"400",
    lineHeight:"22px",
    textAlign:"left",
    marginRight:"3px",
    userSelect:"none",
    cursor:'pointer',
    color:'#0F172A',} as const,
    privacytext2:{fontFamily: "'Poppins', sans-serif",
    fontSize:"14px",
    fontWeight:"400",
    lineHeight:"22px",
    textAlign:"left",
    marginRight:"3px",
    userSelect:"none",
    cursor:'pointer',
    color:'#0F172A',} as const,
    signupTextStylee: {width: "100%",
    padding:"16px",
    fontFamily:"Poppins",
    textTransform:'none',
    color:"#64748B",
    background:"#F1F5F9",
    borderRadius:"8px",
    fontWeight:600,
    fontSize:"16px",
    border:"none",
    cursor:"pointer",
    lineHeight:"24px",
    textAlign:"center",
  } as const,
  signupTextStylewithh: {
    width: "100%",
    padding: "16px",
    border: "none",
    cursor: "pointer",
    color: "#FFFFFF",
    background: "#237182",
    borderRadius: "8px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    fontFamily: "Poppins",
    textTransform: 'none',
  } as const,
  labelTextClientt: {width: "100%",
    padding:"16px",
    border:"none",
    cursor:"pointer",
    color:"#334155",
    borderRadius:"8px",
    fontWeight:400,
    fontSize:"16px",
    lineHeight:"24px",
    textAlign:"center",
    fontFamily:"Poppins",
    textTransform:'none',} as const,
  };
  const inputStyles = {fontFamily: "Poppins",
  fontSize:"16px",
  fontWeight:400,
  lineHeight:"24px",
  textAlign:"left",
  backgroundColor:"#F6F8F9",
  borderRadius:"8px",
  "&:focus":{
    border:"1px solid #CBD5E1",},
  background:"#FCFDFE",
  paddingTop:'8px',
  paddingBottom:'8px',
  marginTop:'6px'} as const
  const inputStylesPass = {fontFamily: "Poppins",
  fontSize:"16px",
  fontWeight:400,
  lineHeight:"24px",
  textAlign:"left",
  backgroundColor:"#F6F8F9",
  borderRadius:"8px",
  "&:focus":{border: "1px solid #CBD5E1",},
  paddingTop:'3px',
  paddingBottom:'3px',
  background:"#FCFDFE",
  marginTop:'6px',} as const
  const errorMessageStyles = {color: 'red',
  fontFamily:'Poppins',
  fontSize:'12px',
  fontWeight:500,
  lineHeight:'16px',
  marginLeft:'0rem',};
  const theme = createTheme({
  overrides: {MuiOutlinedInput:{
      root:{
        '&:hover .MuiOutlinedInput-notchedOutline': {border: '1px solid #CBD5E1'},
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #CBD5E1'},},
    },},
  }
  );
// Customizable Area End